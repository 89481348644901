.team-members
{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 60px;
}

.team-member
{
    margin-bottom: 30px;
}

.team-member img
{
    width: 100%;
}

.team-member-name
{
    font-size: 22rem;
    line-height: 1em;
    margin-top: 16px;
}

.team-member-title
{
    font-size: 18rem;
    opacity: 0.3;
    line-height: 1.5em;
    text-transform: uppercase;
}


@media screen and (max-width: 960px) 
{
    .team-members
    {
        grid-template-columns: repeat(1, 1fr);
    }
}