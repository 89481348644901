#home
{
    min-height: calc(100vh + 30px);
    padding: 100px 0;
    background-image: url('../img/Vulture.svg');
    background-repeat: no-repeat;
    background-position: left 45vw bottom -720px;
    background-size: 2200px auto;
}

#home-title-intro
{
    margin-top: 20vh;
}

#home-title-intro, #home-title-outro
{
    opacity: 0.5;
    font-size: 36rem;
    line-height: 1em;
    text-transform: uppercase;
}

#home-title
{
    font-size: 96rem;
    line-height: 0.8em;
    text-transform: uppercase;
    margin-left: -5px;
}

#home-description
{
    margin-top: 40px;
    width: 100%;
    max-width: 600px;
    white-space: pre-wrap;
}

@media screen and (max-width: 960px) 
{
    #home-title-intro
    {
        margin-top: 10vh;
    }

    #home-title-intro, #home-title-outro
    {
        font-size: 28.8rem;
    }

    #home-title
    {
        font-size: 76.8rem;
        margin-left: -3px;
    }
}