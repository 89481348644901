/* Set padding to be subtracted from height/width instead of added to it */
* { 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box; 
    line-height: 1.3em;
}

a
{
    text-decoration: none;
    color: inherit;
}

html
{
    font-size: 1px;
}

body
{
    background: #ffcc00;
    color: #ffffff;
    font-family: "effra", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 27rem;
}

.page
{
    margin-top: -30px;
    padding-top: 80px;
    padding-bottom: 100px;
    clip-path: polygon(
        0% 0%, 
        calc(((100vw - 1200px) / 2)) 0%,
        calc(((100vw - 1200px) / 2) + 30px) 30px, 
        calc(((100vw - 1200px) / 2) + 60px) 0%,
        100% 0%,
        100% 100%,
        0% 100%);
    background: #000000;
    min-height: calc(100vh + 30px);
    background-size: cover;
    background-position: center center;
}

.content
{
    max-width: 1200px;
    margin: 0 auto;
}

.title-intro
{
    opacity: 0.2;
    text-transform: uppercase;
    line-height: 1em;
}

.title-main
{
    text-transform: uppercase;
    font-size: 47rem;
    line-height: 0.9em;
}


@media screen and (max-width: 1333px) 
{
    .content {
        width: 90vw;
    }

    .page
    {
        clip-path: polygon(
            0% 0%, 
            calc(5vw) 0%,
            calc(5vw + 30px) 30px, 
            calc(5vw + 60px) 0%,
            100% 0%,
            100% 100%,
            0% 100%);
    }
}

@media screen and (max-width: 960px) 
{
    
}