#menu
{
    margin-top: 44px;
    min-height: 50px;
    margin-bottom: -50px;
    width: 100%;
}

#menu-hamburger
{
    display: none;
    float: right;
    background: url('../img/Hamburger.svg') no-repeat;
    background-size: 30px auto;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.menu-social img
{
    height: 30px;
    width: auto;
    margin-left: 10px;
}

.menu-logo
{
    height: 32px;
    margin-left: -3px;
    cursor: pointer;
}

#menu-buttons
{
    float: right;
}

.menu-button
{
    font-size: 24rem;
    line-height: 1em;
    vertical-align: 6px;
    text-transform: uppercase;
    margin-left: 26px;
}

#menu-socials
{
    margin-left: 20px;
}


@media screen and (max-width: 1180px) 
{
    #menu-buttons
    {
        display: none;
    }

    #menu-buttons.open
    {
        display: block;
        float: none;
        margin-top: 27px;
    }

    #menu-hamburger
    {
        display: block;
    }

    .menu-button
    {
        display: block;
        margin-left: 0;
        margin-bottom: 10px;
    }

    #menu-socials
    {
        display: block;
        margin-left: 0;
        margin-top: 27px;
    }

    .menu-social img
    {
        margin-left: 0;
        margin-right: 20px;
    }
}