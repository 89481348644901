.title
{
    background: #ffcc00;
    padding-top: 20px;
    min-height: auto;
    padding-bottom: 40px;
}

.title h1
{
    font-size: 47rem;
    line-height: 1em;
    text-transform: uppercase;
}