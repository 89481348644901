.game-media
{
    margin-top: 40px;
    margin-bottom: 34px;
}

.game-media img
{
    width: 208px;
    height: 117px;
    margin-right: 10px;
}

.game-description
{
    width: 100%;
    max-width: 600px;
    white-space: pre-wrap;
}

.game-service, .game-platform
{
    font-size: 18rem;
    line-height: 1em;
    background: #2baf89;
    padding: 6px 16px;
    border-radius: 4px;
    text-transform: uppercase;
    color: #000000;
    margin-right: 6px;
    display: inline-block;
}

.game-platform
{
    background: #ffcc00;
}

.game-services
{
    margin-top: 40px;
}

.game-services, .game-platforms
{
    width: 100%;
    max-width: 600px;
}

@media screen and (max-width: 960px) 
{
    .game-media img
    {
        width: 100%;
        height: auto;
        margin-right: 0;
    }

}