#contact-description
{
    width: 100%;
    max-width: 600px;
    white-space: pre-wrap;
    margin-top: 40px;
}


#contact-email
{
    margin-top: 27px;
    font-size: 18rem;
    line-height: 1em;
    background: #ffcc00;
    padding: 6px 16px;
    border-radius: 4px;
    text-transform: uppercase;
    color: #000000;
    margin-right: 6px;
    display: inline-block;
}

#contact-socials
{
    margin-top: 60px;
}

.contact-social img
{
    height: 30px;
    width: auto;
    margin-right: 10px;
}
